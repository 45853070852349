<template>
	<el-dialog title="字典项管理" :visible.sync="dictionItemVisible" width="578px" center :close-on-click-modal="false">
		<div class="main-content-title">
			<p>共有 {{ total }} 条结果</p>
			<div class="clearfix">
				<el-button class="float-r" type="primary" @click="modifyItem(1)" icon="el-icon-plus">新增</el-button>
			</div>
		</div>
		<Table
			:loading="loading"
			:table-data="tableData"
			:table-head="tableHead"
			:is-show-selection="false"
			:showOperation="true"
			:current-page.sync="queryData.current"
			:page-size.sync="queryData.size"
			:total="total"
			@change-page="getTableData"
			:operation-column-width="140"
			:tableHeight="tableHeight"
		>
			<!-- 插槽操作栏固定属性operation -->
			<template #operation="{ scope }">
				<el-button @click="modifyItem(1, scope.row)" size="mini" :type="!scope.row.enabled ? 'warning' : 'info'" :disabled="scope.row.enabled">
					修改
				</el-button>
				<el-button @click="modifyItem(2, scope.row)" size="mini" type="danger"> 删除 </el-button>
			</template>
		</Table>
		<CreateDictionary ref="createDictionary" :isShowDetail="false" openType="dictionaryItem" @getTableData="getTableData"></CreateDictionary>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Table: () => import('@/components/Table/table'),
		CreateDictionary: () => import('./newDialog.vue'),
	},
	data() {
		return {
			dictionItemVisible: false,
			saveLoading: false,
			loading: false,
			tableHead: [
				{
					label: '名称',
					prop: 'title',
					formatter: (row) => {
						return row.title || '-';
					},
				},
				{
					label: '编码',
					prop: 'code',
					formatter: (row) => {
						return row.code || '-';
					},
				},
				{
					label: '描述',
					prop: 'description',
					formatter: (row) => {
						return row.description || '-';
					},
				},
			],
			tableData: [],
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			checkRow: {}, //列表选中字典数据
		};
	},
	created() {},
	methods: {
		// 获取列表
		getTableData(dictionaryInfo = {}) {
			console.log(dictionaryInfo, 'dictionaryInfo');
			this.checkRow = { ...dictionaryInfo };
			this.loading = true;
			let data = {
				dictionaryId: dictionaryInfo.id,
				current: this.queryData.current,
				size: this.queryData.size,
			};
			this.$http
				.get(this.api.getDictionaryItems + '.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						res.data.collection.forEach((item) => {
							item.name = item.title;
						});
						this.tableData = res.data.collection || [];
						this.total = res.data.pagination.totalItems || 0;
						this.loading = false;
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		// 操作字典项
		modifyItem(type, row) {
			if (type === 1) {
				this.$refs.createDictionary.init(row, this.checkRow);
			} else {
				if (!row.id) return false;
				this.$confirm('此操作将删除数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						this.$http
							.delete(this.api.getDictionaryItems + `/${row.id}.json`)
							.then((res) => {
								if (res.data && res.data.success) {
									this.$message({
										type: 'success',
										message: '删除成功!',
									});
									this.getTableData(this.checkRow);
								}
							})
							.catch((e) => {});
					})
					.catch(() => {});
			}
		},
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
};
</script>

<style lang="scss" scoped>
.main-content-title {
	margin-bottom: 10px;
}
</style>